.cluster-container {
  margin: 10px 0 0 0;
  padding: 0 28px 0 0;
  border: solid 0 #bcbcbc;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.clusterRowWrapper {
  display: flex;
  flex-wrap: wrap;
}

.cluster-row {
  width: 90px;
  height: 24px;
  margin: 10px 5px 0 0;
  padding: 5px;
  border-radius: 12px;
  background-color: var(--background-grey-7);
}

.cluster-name {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  width: 75%;
  display: inline-block;
}
