.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.p-10 {
  padding: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer:not([disabled]) {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.clamp-two-lines {
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
}
