.slider-container {
  display: flex;
  flex: 1;
  /* flex-direction: column; */
}

.slider-thumbnail {
  flex: 1;
  max-width: 60px;
  max-height: 250px;
}

.slider-regular-narrow {
  flex: 1;
  max-width: 400px;
  max-height: 600px;
}

.slider-regular-wide {
  flex: 1;
  max-width: 100%;
  max-height: 600px;
}

.product-regular {
  display: flex;
  flex: 10;
  justify-content: center;
  width: calc(100% - 60px);
  position: relative;
}

.product-thumbnail {
  display: flex;
  flex: 1;
}

.product-media {
  background-color: var(--background-grey-3);
}

.product-media-slider {
  background-color: var(--background-white);
}

.pointer360 {
  cursor: not-allowed;
}
