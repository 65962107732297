/*
 * @Author: Raeesaa Metkari 
 * @Date: 2019-11-20 16:30:55 
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-11-26 00:05:42
 */

.root {
  height: 24px;
  background: white;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.05);
  border-bottom: solid 2px rgba(0, 0, 0, 0.05);
}
