.typography--variant-h1 {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-title {
  font-family: var(--base-font-family);
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-h2 {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-h3 {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-h1Custom {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
}

.typography--variant-h1SidebarMenu {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  padding: 17px 0px;
  margin: auto;
}

.typography--variant-value {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
}

.typography--variant-toolBarTitle {
  font-family: var(--base-font-family);
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
}

.typography--variant-subHeading1 {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-subHeading2 {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-body1 {
  font-family: var(--base-font-family);
  font-size: 0.625rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-body2 {
  font-family: var(--base-font-family);
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-section {
  font-family: var(--base-font-family);
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-section2 {
  font-family: var(--base-font-family);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
}

.typography--variant-section3 {
  font-family: var(--base-font-family);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-caption {
  font-family: var(--base-font-family);
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-label {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-toggle {
  font-family: var(--base-font-family);
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
}

.typography--variant-caption2 {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-subHeading3 {
  font-family: var(--base-font-family);
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-list {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-subCategory {
  font-family: var(--base-font-family);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-subCategoryCustom {
  font-family: var(--base-font-family);
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-lookTitle {
  font-family: var(--base-font-family);
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-detailTitle {
  font-family: var(--base-font-family);
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-checkboxlabel {
  font-family: var(--base-font-family);
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.typography--variant-buttonlabel {
  font-family: var(--base-font-family);
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.typography--variant-custom {
  font-family: var(--base-font-family);
  font-size: 0.875rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 1.25px;
}

.typography--variant-subHeading4 {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-toolbarHeader {
  font-family: var(--base-font-family);
  font-size: 1.375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.typography--variant-subHeading5 {
  font-family: var(--base-font-family);
  font-size: 1.375rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.typography--variant-hierarchy {
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.typography--color-label {
  color: rgba(128, 128, 128, 0.87);
}

.typography--color-primary {
  color: var(--primary-color);
}

.typography--color-secondary {
  color: var(--secondary-color);
}

.typography--color-error {
  color: var(--error-color);
}

.typography--color-hierarchy {
  color: var(--hierachy-grey);
  text-transform: capitalize;
}

.typography--color-qty-labels {
  color: var(--light-grey-color);
}

.typography--color-blue {
  color: var(--blue-color);
}

.typography--align-inherit {
  text-align: inherit;
}

.typography--align-left {
  text-align: left;
}
.typography--align-center {
  text-align: center;
}
.typography--align-right {
  text-align: right;
}
.typography--align-justify {
  text-align: justify;
}

.typography--decorator-under {
  text-decoration: underline;
}

.typography--decorator-over {
  text-decoration: overline;
}

.typography--decorator-line {
  text-decoration: line-through;
}

.typography--decorator-plain {
  text-decoration: none;
}

.typography-disabled {
  cursor: not-allowed !important;
  opacity: 0.2;
  pointer-events: none;
}

.typography--variant-body2Custom {
  font-family: var(--base-font-family);
  font-size: 9px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.typography--variant-subHeading2Custom {
  font-family: var(--base-font-family);
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
