.root .root .chip {
  margin: 0px 5px 5px 0px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}
.root .chipRoot {
  height: 28px;
  max-width: 179px;
  margin: 2px;
}
.root .label {
  padding-left: 6px;
}
p.tag {
  font-weight: bold;
}
