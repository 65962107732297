.product-detail-rating {
  /*width: 350px;*/
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: center;
}

.product-detail-rating-value {
  display: flex;
  flex: 70%;
}

.product-detail-rating-name {
  display: flex;
  flex: 30%;
}

.product-detail-rating-avg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-detail-rating-avg > svg {
  margin: 8px;
}
