/*
 * @Author: Raeesaa Metkari 
 * @Date: 2019-12-03 20:20:22 
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-05-29 18:25:05
 */

.productCard {
  margin-right: 20px;
  margin-bottom: 20px;
}

.selectedProduct {
  display: flex;
  border-radius: 2px;
  border: 2px solid grey;
}

.productCardWithoutBorder {
  padding: 2px;
  display: flex;
  border-radius: 2px;
}

.productInfoItem .chip {
  margin: 0px 5px 5px 0px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}

.productInfoItem .chipRoot {
  height: 28px;
  max-width: 179px;
}

.productInfoItem .label {
  padding-left: 6px;
}

p.contentText {
  width: 184px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #000000;
  margin-top: 5px;
}

.dropTargetClass {
  display: flex;
  height: 706px;
}

.productDetailsContainerWithoutOverflow {
  height: 470px;
}

.productDetailsContainerWithoutOverflowAndAction {
  height: calc(470px + var(--product-card-footer));
}

.productActions {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  box-shadow: 0 -2px 5px 0 rgba(225, 225, 225, 0.5);
  height: var(--product-card-footer);
}

.productInfoItem {
  border-top: 1px solid #eeeeee;
}

p.tag {
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

.productActions .counts {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.47px;
  margin-left: 5px;
}

@media (max-width: 1366px) {
  .productDetailsContainerWithoutOverflow {
    height: 285px;
  }

  .productDetailsContainerWithoutOverflowAndAction {
    height: calc(285px + var(--product-card-footer));
  }

  .productDetailsContainer {
    height: 285px;
  }

  .dropTargetClass {
    height: 520px;
  }
}

.productGridwrapper {
  display: flex;
  flex-direction: column;

  .checkboxContainer {
    padding-left: 0px;
  }
}

/* For small product card, only displaying it in add library sidebar currently */
.productDetailsContainerWithoutOverflow_small {
  height: calc(310px + var(--product-card-footer));
}

.productDetailsContainerWithoutOverflowAndAction_small {
  height: 310px;
}

.dropTargetClass_small {
  height: 510px;
}
