.container {
  margin-bottom: 10px;
  margin-top: 10px;
}
p.label {
  color: black;
  font: var(--typography-body3);
  text-transform: uppercase;
  letter-spacing: 0.21;
  text-decoration: underline;
  cursor: pointer;
}

.warning {
  color: var(--red);
  font: var(--typography-body3);
  text-transform: uppercase;
  letter-spacing: 0.21px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
