.textarea {
  resize: none;
}

::placeholder {
  width: 156px;
  height: 21px;
  font-family: var(--base-font-family);
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--placeholder-grey);
}
