.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  position: relative;
  height: 100%;
  width: 400px;
  overflow: scroll;
}

.flickity-viewport::-webkit-scrollbar {
  display: none;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.carousel {
  width: 480px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.carousel-nav {
  transform: rotate(90deg) translate(-10%, -50%);
  padding: 0 0 20px 0;
  transform-origin: left;
}

.carousel-nav .carousel-cell {
  position: relative;
  transform: rotate(-90deg);
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.carousel-nav .carousel-cell-3D-view {
  pointer-events: auto;
}

.carousel-nav .carousel-cell-img {
  width: 70px;
  height: 90px;
}

.carousel-nav .carousel-cell-img-video-thumb {
  opacity: 0.6;
}

.carousel-nav .carousel-cell-img-video-icon {
  position: absolute;
  top: 45%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.carousel-nav .flickity-prev-next-button {
  width: 44px;
  height: 44px;
  background: transparent;
  border-width: 0;
  position: absolute;
  transform: translateY(100%);
}

.carousel-nav .flickity-prev-next-button.previous {
  left: 0;
}

.carousel-nav .flickity-prev-next-button > svg {
  width: 20px;
  height: 20px;
}

.carousel-nav .flickity-prev-next-button.next {
  right: 0;
}

.carousel-nav .flickity-prev-next-button .arrow {
  fill: var(--carousel-arrows);
}

.carousel-nav .flickity-prev-next-button[disabled] {
  display: none;
  /* .arrow {
     fill: #adadad;
  }
   * */
}
