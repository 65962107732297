.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.productInfoItem .chip {
  margin: 0px 5px 5px 0px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}
.productInfoItem .chipRoot {
  height: 28px;
  max-width: 179px;
}
.productInfoItem .label {
  padding-left: 6px;
}
p.tag {
  font-weight: bold;
}
