.zoneErrorsContainer {
  padding-bottom: 15px;
}

.zoneErrorsHeader {
  font-weight: normal;
  font-size: small;
}

.zoneName {
  color: black;
  font-weight: bold;
}
