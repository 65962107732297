.product-media {
  position: relative;
  height: 100%;
}

.product-media-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.product-media-card-img {
  object-fit: contain;
}

.product-media-360 {
  height: 100%;
  width: 100%;
  border: 0;
}

.product-media-video {
  height: 100%;
  width: 100%;
}

.product-media-watermark {
  transform: rotate(-55deg);
  position: absolute;
  bottom: 43%;
  font-size: 62px;
  color: var(--watermark-color);
}

.product-media-inactive {
  opacity: 0.5;
  pointer-events: none;
}
