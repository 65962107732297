/* This is only because webstorm can't find these css variables if declared in styled components directly */
.root {
  --split-border-color-light: #e4e5e7;
  --split-border-color-dark: #d3d3d3;

  --split-border-radius: 4px;
  --split-row-label-color: #676767;

  --split-product-table-details-cell-border-color: #e4e5e7;
}

.label {
  padding-left: 12px;
}

.chip {
  margin: 4px;
  background: #ffffff !important;
  border: 1px solid lightgrey !important;
}

.clusterColumn {
  height: 150px !important;
  width: 138px;
}

.clusterChips {
  flex-direction: column;
  display: flex;
  padding: 4px;
  height: 150px;
}

.hoverScrollBar {
  &:hover {
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      background: #7fb3ff;
      border-radius: 50;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  }
}

.standardTooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 0.625rem;
  font-family: Roboto;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: #616161;

  &::before {
    content: unset;
  }
}
