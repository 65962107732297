.productDetailRatingContainer {
  max-height: 150px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.productDetailRating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productDetailRatingName {
  width: 30%;
  margin: 8px 8px 8px 0;
}

.productDetailOverallRatingValue,
.productDetailRatingValue {
  width: 70%;
  display: flex;
  align-items: center;
}

.productDetailOverallRatingValue {
  margin: 8px 8px 8px 15px;
}

.productDetailOverallValue {
  margin-left: 15px;
}
