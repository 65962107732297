.container {
  display: flex;
  flex-direction: column;
  width: 521px;

  .commentContainer {
    margin-top: 10px;
  }

  .saCommentContainer {
    padding-right: 5px;
    overflow: auto;
  }

  .commentInfo {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.38);
  }

  .commentItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .text {
      text-overflow: ellipsis;
      /* autoprefixer: off */
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      width: 470px;
    }
    .saTextContainer {
      width: 200px;
    }

    .icon {
      cursor: pointer;
    }

    .disabled {
      opacity: 0.5;
    }
  }
}

.isSACommentContainer {
  width: 100%;
}

.anchor {
  cursor: pointer;
  color: var(--denim-blue);
}
