.wrapper {
  display: flex;
  flex: 1;
  overflow: auto;
}

.root {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > :first-child {
    flex: 0 0 auto;
  }

  > :last-child {
    flex: 1;
    overflow: auto;
  }

  flex: 1;
  background-color: var(--background-paper);
}
