.errorMessageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.errorMessagePaper {
  padding: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  width: 100%;
  text-align: center;
}
