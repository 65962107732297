.core-icon {
  background: var(--light-grey-color);
  position: absolute;
  top: 0;
  right: 0;
  width: 58px;
  height: 19px;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-shifted-core-icon {
  top: 21px;
}

.left-shifted-core-icon {
  left: var(--thumnail-width);
}

.core-icon .core-icon-text {
  text-transform: uppercase;
}
