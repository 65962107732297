.button {
  cursor: pointer;
  /* padding: 11px 46px 12px;
  height: 40px; */
}

.button-size-normal {
  padding: 0 43px 0;
}

.button + .button {
  margin: 0 0 0 23px;
}

.button-primary {
  border: none;
}

.button-primary-dark {
  background-color: #c4c4c4;
  color: var(--black);
}

.button-primary-light {
  background-color: var(--white);
  color: var(--primary-color);
}

.button-primary-custom {
  background-color: #c4c4c4;
  font-weight: 500;
}

.button-primary-customLight {
  background-color: var(--white);
  color: var(--inActive);
}

.button-secondary {
  border: 1px solid;
}

.button-secondary-dark {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--white);
}

.button-secondary-light {
  background-color: var(--white);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.button-link {
  border: none;
  background-color: transparent;
  color: var(--blue-color);
}

.button-icon {
  border: none;
  background-color: transparent;
}

.button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button:focus {
  outline: none;
}
