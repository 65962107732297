.root {
  margin: 20px 22px 28px 22px;
}
.skeletonContainer {
  margin: 22px;
}
.lineWrapper {
  display: flex;
}
.labelSkeleton {
  margin-bottom: 10px;
}
.radioButtonSkeleton {
  width: 40px;
}
.valueSkeleton {
  width: 260px;
}
p.legend {
  font: var(--typography-body4);
  text-transform: uppercase;
}
.radioGroupContainer {
  margin: 10px 0px 10px;
}
.radioLabel {
  width: fit-content;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.disabledMessage {
  color: var(--red);
  font-weight: bold;
  margin: auto 140px auto auto;
}
