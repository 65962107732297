.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-grey-7);
  padding: 0 25px;
  width: 100px;
  height: 30px;
  border-radius: 16px;
  margin: 0 10px 10px 0;
}
