.icon > img {
  height: 24px;
  width: 24px;
}
.icon {
  height: 24px;
  width: 24px;
}

.icon-16 {
  height: 16px;
  width: 16px;
}
