.popupRoot {
  overflow: 'auto';
  height: '100vh';

  .container {
    display: 'flex';
    justify-content: 'flex-start';
    align-items: 'left';
    flex-direction: 'column';

    .footer {
      display: flex;
      padding: 14px 0;
      margin-left: 20px;
      margin-right: 20px;
      justify-content: 'flex-end';

      .cancelButton {
        font-weight: 300;
      }

      .backButton {
        font-weight: 300;
        margin-right: auto;
      }

      .buttonProgress {
        color: var(--darkgrey);
      }
    }
    .borderTop {
      border-top: 1px solid var(--lightgrey);
    }
  }
}

.formHeader {
  width: 100%;
  height: 16px;
  font: var(--typography-body1);
  letter-spacing: 0.14px;
  color: var(--black);
}

.formHeaderText {
  border-bottom: 1px solid var(--lightgrey);
  padding-bottom: 5px;
}
