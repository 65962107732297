.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tags-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.productDetailPopup {
  padding: 0 10px;
  width: 90px;
  height: 30px;
  border-radius: 16px;
  margin: 10px 10px 0 0;
}
