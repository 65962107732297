.comment-container {
  width: 600px;
}

@media only screen and (max-device-width: 1366px) {
  .comment-container {
    width: 521px;
  }
}

.comment-add-container {
  display: flex;
  flex-direction: column;
}

.comment-add-save {
  width: fit-content;
  padding: 10px 20px 10px 20px;
  margin: 10px 0 20px 0;
}

.comment-row-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.comment-row-head {
  display: flex;
  justify-content: space-between;
}

.comment-row-action {
  display: flex;
  align-items: baseline;
}

.comment-row-action-icon {
  margin-left: 5px;
}
