.root .title {
  font: var(--typography-body3);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.87);
}

.root .valueWithTitle {
  font: var(--typography-font-bold1);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  color: #000000;
  line-height: 11px;
  max-height: 22px;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  padding: 1px;
}

.root .descriptionValue {
  font: var(--typography-body3);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  color: #000000;
  line-height: 16px;
  max-height: 22px;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  padding: 1px;
}
.root .description {
  font: var(--typography-font-bold1);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.33px;
  color: #000000;
  line-height: 16px;
  max-height: 32px;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  padding: 1px;
}

.productInfo {
  padding: 3px 10px 3px 10px;
}

.root .skuStyle {
  font: var(--typography-body3);
  width: 184;
  height: 14;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000000;
  margin-top: 5;
}
