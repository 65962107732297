.recoTag {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  top: 10px;
  z-index: 1;
}

.recoText {
  color: var(--red);
  font: var(--typography-font-bold1);
  text-transform: uppercase;
}

.root {
  .icon {
    fill: var(--red);
    width: 0.7em;
    height: 0.7em;
  }
}
