span.tabsIndicator {
  display: flex;
  justify-content: center;
  height: 4px;
  background-color: transparent;
  & > div {
    width: 22px;
    height: 2px;
    border: 2px solid #000000;
  }
}

button.tabSelected {
  font-weight: bold !important;
}
