.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
}

p.label {
  font: var(--typography-body3);
  padding: 5px 10px 7px 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

p.value {
  font: var(--typography-body4);
  padding: 0px 10px 7px 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
}
p.boldValue {
  font: var(--typography-display5);
  padding: 0px 10px 7px 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
}
p.singleSizedProduct {
  font: var(--typography-font-bold0);
  text-transform: uppercase;
  text-align: center;
}
