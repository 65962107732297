.root {
  z-index: 1;
  display: flex;
  align-items: center;
  .icon {
    color: var(--grey);
    cursor: 'pointer';
    margin: 0px 17px 0px 17px;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
