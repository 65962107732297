.root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity var(--animation-medium);

  &,
  * {
    color: var(--secondary);
  }
}
