.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between;
}
