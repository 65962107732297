.main {
  padding: 0px 17px 0px 17px;
}
.popOver {
  width: 64px !important;
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.2) !important;
  background-color: #ffffff !important;
  padding: 0px !important;
}
.wholesalePopOver {
  width: 64px !important;
  height: 126px !important;
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 0px !important;
}
.menuItemRoot {
  height: 63px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

li.disableMenuRoot {
  opacity: 0.5;
  cursor: not-allowed;
  & > div {
    pointer-events: none;
  }
}

.menuItemSelector {
  height: 63px !important;
}
.icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.menuText {
  height: 14px;
  font-family: 'Roboto' !important;
  font-size: 12px !important;
  font-stretch: 'normal' !important;
  font-style: 'normal' !important;
  line-height: 'normal' !important;
  letter-spacing: 0.09px;
  text-align: 'center' !important;
  color: '#4a5565' !important;
}
.boldFont {
  font-weight: bold !important;
}
.normalFont {
  font-weight: 'normal' !important;
}
.image {
  width: 'fit-content';
  height: 'fit-content';
}
.menuItem {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 2px rgba(0, 0, 0, 0.1);
}
