.container {
  display: flex;
}

.previewContainer {
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
}
.previewContainer::-webkit-scrollbar {
  display: none;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
.infoContainer::-webkit-scrollbar {
  display: none;
}
