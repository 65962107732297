.productDetailContainer .contentText {
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  color: #000000;
  line-height: 11px;
  max-height: 22px;
  text-overflow: ellipsis;
  /* autoprefixer: off */
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}
.productDetailContainer .titleText {
  font-family: 'Roboto';
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2px;
}
.productDetailContainer .productInfo {
  padding: 0px 10px 10px 10px;
}
