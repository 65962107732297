.root {
  border-bottom: 1px solid black;

  .formControl {
    margin: 8px 0;
    min-width: 120px;
  }

  .filledInput {
    padding: 5px;
  }

  .paddingLeft16 {
    padding-left: 16px;
  }

  .noPadding {
    padding: 0;
  }
}
