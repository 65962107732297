/*
 * @Author: Raeesaa Metkari 
 * @Date: 2019-11-20 17:19:00 
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2019-11-27 15:52:32
 */

.text-input__loading {
  height: 496px;
  margin: 0px 20px 20px 0px;
  /* border: 1px solid lightgrey; */
  border-radius: 4px;
  width: 150px;
  /* margin: 10px; */
}

.text-input__loading--line {
  height: 20px;
  width: 150px;
  /* margin: 10px 5px; */
  margin-bottom: 5px;
  animation: pulse 1s infinite ease-in-out;
}

.image__loading--line {
  height: 200px;
  width: 150px;
  /* margin: 10px 5px; */
  margin-bottom: 5px;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
