.root {
  height: 100vh;
  font-size: 16px;
}

:root {
  --open-nav-bar-width: 240px;
  --app-bar-width: 60px;

  --container-margin: 10px;

  --vertical-scroll-width: 17px;
  --card-spacing: 0.5em;
  --primary: #424242;
  --primary-dark: #1b1b1b;
  --primary-light: #6d6d6d;
  --primary-red: rgba(236, 29, 29, 0.87);

  --secondary: #4184f3;
  --secondary-light: #7fb3ff;
  --secondary-dark: #0058c0;

  --primary-button-hover-background: var(--secondary-dark);
  --secondary-button-hover-background: rgba(153, 153, 153, 0.1);
  --tertiary-button-hover-background: rgba(27, 27, 27, 0.05);
  --border-color: #dedede;

  --selected-background: rgba(65, 132, 243, 0.15);
  --selected-background-no-transparent: #e3edfc;
  --table-focused-cell-box-shadow: inset 0px 0px 1px 1px var(--tertiary-green);
  --table-hover-cell-box-shadow: inset 0px 0px 1px 1px var(--tertiary-orange);

  --background-white: #ffffff;
  --background-grey-2: #fafafa;
  --background-grey-3: #f5f5f5;
  --background-grey-4: #eeeeee;
  --background-grey-5: #e0e0e0;
  --background-grey-6: #bdbdbd;
  --background-grey-7: #f3f3f3;

  --tertiary-grey: #767676;
  --tertiary-green: #4caf50;
  --tertiary-pink: #ff99c1;
  --tertiary-red: #f44336;
  --tertiary-purple: #7e57c2;
  --tertiary-yellow: #ffc107;
  --tertiary-orange: #ffa337;
  --yellow: #f7b500;
  --mid-grey: var(--background-grey-5);

  --icon-grey-1: #212121;
  --icon-grey-2: #9e9e9e;
  --count-color: #94969f;

  --disabled-color: var(--icon-grey-2);
  --display-color: rgba(27, 27, 27, 0.87);
  --typography-display3: Normal 34px Roboto;
  --typography-display3-color: var(--display-color);
  --typography-display2: Normal 24px Roboto;
  --typography-display4: Bold 24px Roboto;
  --typography-display5: Bold 20px Roboto;
  --typography-display2-color: var(--display-color);
  --typography-display1: Bolder 20px Roboto;
  --typography-display1-color: var(--display-color);
  --typography-headline: Normal 16px Roboto;
  --typography-headline-bold: Bolder 16px Roboto;
  --typography-headline-color: var(--display-color);
  --typography-title: var(--typography-headline);
  --typography-title-color: var(--display-color);
  --typography-body1: Normal 14px Roboto;
  --typography-body1-color: var(--display-color);
  --typography-body2: Normal 12px Roboto;
  --typography-body3: Normal 10px Roboto;
  --typography-body5: Normal 11px Roboto;
  --typography-body6: Normal 8px Roboto;
  --typography-body4: Bold 14px Roboto;
  --typography-body2-color: rgba(27, 27, 27, 0.54);
  --typography-caption: Normal 10px;
  --typography-caption-color: rgba(27, 27, 27, 0.54);
  --typography-button: Normal 14px;
  --typography-product-header-size: 18px;
  --typography-button-color: var(--secondary);
  --typography-font-bold: Bold 12px Roboto;
  --typography-font-bold1: Bold 10px Roboto;
  --typography-font-bold0: Bold 8px Roboto;
  --animation-fast: 200ms ease;
  --animation-medium: 400ms ease;
  --animation-slow: 600ms ease;

  --hr-color: #ececec;
  --lightgrey: #eeeeee;
  --midgrey: #e0e0e0;
  --grey: #6d6d6d;
  --darkgrey: #424242;
  --regent-grey: #8592a6;
  --grey2: #b0b0b0;
  --grey3: #f9f9f9;
  --black: #000000;
  --Whitesmoke: #f6f6f6;
  --red: #ff0000;
  --grey-pause-status: #827d7d;
  --grey-channel-color: #bcbcbc;
  --red2: #d0021b;
  --orange: #ffab07;
  --defaultTextColor: #182026;

  --inActive: #717171;
  --active: #73b716;

  --background-paper: #fff;
  --spacing-unit: 8px;

  --card-hover-border-color: lightskyblue;
  --denim-blue: #0064b2;

  --product-card-footer: 34px;
  /*
  Table view cell height
*/
  --cell-height: 23px;
  --cell-border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  --cell-padding: 0px 10px 0 10px;
  /*
  Chips
*/
  --chip-border-radius: 16px;
  --chip-background-color: rgba(0, 0, 0, 0.05);
  --chip-size-small: 18px;
  --chip-size-normal: 32px;
  --chip-margin: 0px 5px 5px 0px;
  --chip-typography-letterSpacing: 0.37px;
  --chip-typography-color: #4a5565;
  --chip-color-circle-height: 10px;
  --chip-color-circle-width: 10px;
  --chip-color-circle-borderRadius: 10px;
  --chip-typography-max-width: 89px;

  /*
  LabelList
*/
  --label-typography-letter-spacing: 0.07px;

  /*
  Split Button 
*/
  --split-button-box: 20px;
  --split-button-icon: 18px;

  /* Left Navigation */
  --navbar-expanded-width: 245px;
  --navbar-collapsed-width: 25px;
  --margin-left: 60px;
  /* AppBar Logo */
  --appbar-logo-dimensions: 48px;

  /* Tool bar header */
  --toolbar-header: Normal 21px Roboto;
  --toolbar-height: 60px;

  /* Table cell */
  --tablecell-font-bold: Bold 18px Roboto;
  --tablecell-border: solid 1px #d3d3d3;
  --table-border: solid 1px #eeeeee;

  --letter-spacing: 0.13px;

  --cluster-setting-margin: 30px;

  /* Buying Session ToolBar */
  --toolbar-session-header: Normal 18px Roboto;

  /* Product Slider */
  --thumnail-width: 50px;
  --thumnail-height: 50px;

  /* Customer Portal */
  --sidebar-width: 175px;
  --sidebar-header-height: 20vh;
  --sidebar-body-height: 64vh;
  --sidebar-footer-height: 12vh;
  --medium-sized-product-card-width: 180px;
  --small-sized-product-card-width: 150px;
  --large-sized-product-card-width: 250px;
  --extra-small-sized-product-card-width: 108px;

  --primary-color: #000000;
  --secondary-color: #6f6f6f;
  --error-color: #fd0202;
  --white: #fff;
  --watermark-color: #dadada;
  --light-grey-color: #979797;
  --placeholder-grey: #797979;
  --carousel-arrows: #232323;
  --hierachy-grey: #414141;

  --blue-color: #0091ff;

  --base-font-family: 'Roboto', Arial;
  --base-font-size: 18px;
  --base-font-size-14: 14px;

  --spacing: 8px;

  --color-value-green: #5ea600;
}
