.carousel {
  display: flex;
}
.carouselBox {
  width: 120px;
  height: 480px;
}
.carouselPreview {
  width: 470px;
  height: 620px;
}
