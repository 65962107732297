.root {
  background: var(--background-paper);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .container {
    overflow: hidden;
  }
}
