.product-operation-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.product-media-section {
  height: 100%;
}

.product-operation-icon-bottom-left {
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
}

.product-operation-icon-bottom-right {
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
}

.product-operation-icon-top-left {
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.product-operation-icon-top-right {
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
}

.product-operation-video-icon {
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 60;
}
