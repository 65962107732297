.cursor {
  cursor: pointer;
}
.productCard {
  width: 176px;
  position: relative;
}
.productInfo {
  width: 176px;
  margin: 0px 0px 5px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.statusTag {
  top: 32px;
  position: sticky;
}
.deleteIcon {
  position: absolute;
  top: 30px;
  left: 153px;
  cursor: pointer;
}
.flagIcon {
  position: absolute;
  top: 32px;
  right: 23px;
  cursor: pointer;
}
p.descriptionText {
  font-family: 'Roboto';
  height: 28px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  color: #000000;
}
.productStatus {
  position: relative;
}
.icon {
  position: sticky;
  cursor: pointer;
}
.restrictionText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  z-index: 3;
  font-weight: bold;
  display: flex;
  text-align: center;
  white-space: nowrap;
}
.warningIcon {
  margin: auto;
}
