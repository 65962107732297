/*
 * @Author: Raeesaa Metkari 
 * @Date: 2020-08-21 21:17:34 
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-08-21 22:26:34
 */

.fileUploadWrapper {
  padding: 20px 0 10px 0;

  .error {
    margin-top: 10px;
    text-align: center;
    color: #ac2828;
  }
}
