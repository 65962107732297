.productDetailWrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  padding: 0px !important;
  .status {
    position: sticky;
    z-index: 1;
  }
  .inactive {
    opacity: 0.5;
  }
  .productDetailHeaderWrapper {
    background: #f9f9f9;
    height: 44px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 44px;
    .back {
      flex-grow: 1;
      flex-basis: auto;
      color: #000;
      font-size: 15px;
      line-height: 44px;
      min-width: 100px;
    }
    .descriptionContainer {
      .longDescContainer {
        margin-right: 10px;
        .longDescContent {
          font: var(--typography-headline-bold);
          text-transform: uppercase;
          max-width: 500px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .productDetailContentWrapper {
    display: flex;
    padding: 20px 40px;
    flex-direction: row;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .lookContainer {
    margin: 40px 20px 0 20px;
    .text {
      margin-bottom: 10px;
    }
    .lookImageContainer {
      .lookImage {
        margin-right: var(--container-margin);
      }
      .lookNameMargin {
        margin-top: var(--spacing-unit);
        margin-bottom: var(--spacing-unit);
      }
    }
  }

  .productAttributeContainer {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex: 1;
    .attributeInfo {
      border-bottom: 1px solid rgb(0, 0, 0, 0.1);
      padding: 20px 0px 20px 0px;
    }
    .contentText {
      font: var(--typography-headline);
      font-weight: normal;
      font-stretch: normal;
      color: rgba(0, 0, 0, 0.87);
      line-height: 0.8;
      letter-spacing: 0.67px;
    }
    .priceAttributeValue {
      font-weight: bold;
    }
    .titleText {
      font: var(--typography-body1);
      margin-bottom: 10px;
    }
  }
  .commentsContainer {
    margin-top: 20px;
    display: flex;
    flex: 1;
    overflow: auto;
    min-height: 100px;
  }
}

.headerBtn {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  text-transform: uppercase;
  padding-top: 0;
  padding-bottom: 0;
  .backTitleContainer {
    font-size: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .headerIcon {
      opacity: 0.2;
    }
  }
}

.descriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.basketIconContainer {
  margin-left: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  .bgSessionProductNumber {
    font-weight: bold;
    margin-left: 12px;
    line-height: normal;
  }
}

.divider {
  width: 1px;
  height: 21px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
