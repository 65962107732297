.tabsRoot {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
.tabsIndicator {
  background-color: 1px solid black;
}
.tabRoot {
  text-transform: capitalize;
  padding: 6px 4px;
  min-width: 72px !important;
  font-weight: 18px;
  margin-right: 32px;
  padding: 6px 4px !important;
  margin-right: 8px;
  &:hover {
    color: var(--primary-dark);
    opacity: 1;
  }
  .tabSelected {
    color: var(--primary-dark);
    font-weight: 18px;
    border-bottom: 2px solid red;
  }
  &:focus {
    color: var(--darkgrey);
  }
}

.tabsRoot .tabIconWrapper {
  display: flex;
  flex-direction: row !important;
}

.tabsRoot .tabLabelContainer {
  padding: 6px 4px !important;
  margin-right: 8px;
}
.tabsRoot .tabHeaders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px !important;
  margin: 0px 16px;
  border-bottom: 1px solid #e8e8e8;
}

.tabRoot .tabIcons {
  font-size: 14px !important;
}

.tabRoot .tabLabelIcon {
  min-height: 0px !important;
}

.tabRoot .icon {
  margin: 8px;
  color: lightgrey;
  cursor: pointer;
}
