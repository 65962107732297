@media print {
  body {
    overflow: auto;
    height: auto;
    color: black;
    width: 100%;
    margin: 0;
    padding: 0;
    background: none;
  }

  header,
  .sidebar {
    display: none !important;
  }

  * {
    -webkit-print-color-adjust: exact;
  }
}
