.root {
  display: flex;
  align-items: center;
}

.root .counts {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.47px;
  margin-left: 5px;
}
