/*
 * @Author: Raeesaa Metkari 
 * @Date: 2019-12-19 00:14:29 
 * @Last Modified by: Raeesaa Metkari
 * @Last Modified time: 2020-01-08 19:26:16
 */

.text-input__loading {
  height: 210px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.default_margin {
  margin: 10px 20px 10px 0;
}

.container_margin {
  margin: 0 20px 0 0;
  padding-top: 10px;
}

.row-wrapper {
  flex: 1 0 auto;
  padding-top: 4px;
}

.text-input__loading--line {
  height: 13px;
  margin-bottom: 10px;
  border-radius: 2px;
  animation: pulse 1s infinite ease-in-out;
}

.image__loading--line {
  flex: 0 0 auto;
  width: 204px;
  margin: 0 10px 0 0;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
