.collapsible-container {
  width: 50vw;
}
.collapsible-container .panel {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.collapsible-container .panel-item {
  width: 50%;
  flex-grow: 1;
  text-transform: capitalize;
}
