.root {
  display: flex;
  flex-direction: column;
  > * {
    flex: 1;
  }
}

.grid {
  margin-bottom: 1em;
  max-height: 40vh;
  border-left: none;

  :global {
    [wj-part='ch-cells'] {
      border-left: var(--table-cell-border);
    }

    [wj-part='cells'] {
      .wj-cell {
        &:first-child {
          border-right: none;
        }
      }
    }
  }

  .cell {
    vertical-align: center;
  }

  .cellContainer {
    width: 100%;
    min-height: 100%;
    flex: 1;
    align-items: center;
    display: flex;
    align-self: stretch;
    overflow: hidden;

    :global {
      .bp3-popover-wrapper,
      .bp3-popover-target {
        width: 100%;
        flex: 1;
        display: flex;
      }
    }
  }
}

.infinite {
  max-height: 40vh;
}
