.collapsible-panel-title {
  padding: 0;
  display: flex;
  width: fit-content;
  align-items: center;
}

.collapsible-panel-title-icon {
  margin: 0 0 0 8px;
}

.collapsible-panel-title-full {
  display: flex;
  align-items: center;
  position: sticky;
  top: -1px;
  z-index: 31;
  border-bottom: 1px solid;
  width: 100%;
  padding: 0 20px 0 0px;
  justify-content: space-between;
  background-color: white;
}
