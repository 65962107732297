.gridRoot {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.flagAndChipsContainer {
  display: flex;
}

.flagIcon {
  height: 14.2px;
  width: 12.5px;
  margin-left: 5px;
}

.flagIcon > img {
  height: 14.2px;
  width: 12.5px;
}
