.product-info {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: 100%;
}

.product-info-divider {
  width: 91px;
  height: 2px;
  margin: 8px 0 2px 0;
  border: solid 1px var(--primary-color);
}
