* {
  font-family: Roboto;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-transition: font-size 300ms;
  -moz-transition: font-size 300ms;
  -o-transition: font-size 300ms;
  -webkit-overflow-scrolling: touch;
  transition: font-size 300ms;
  overflow: hidden;

  .wj-cell.wj-group {
    background: #fff;
  }

  .wj-cells.wj-cell.wj-state-selected {
    background: #3f51b5;
  }

  .wj-cell.first-level {
    font-weight: bold;
  }

  .wj-cell.second-level {
    font-weight: bold;
    color: #676767;
  }

  .highlight-mismatch {
    background: #ecec12;
  }

  .wj-cell.wj-alt.highlight-mismatch {
    background: #ecec12;
  }

  .wj-cell.wj-alt.highlight-mismatch.wj-state-selected {
    background: #3f51b5;
  }

  .wj-flexgrid .wj-state-sticky .wj-header {
    opacity: 1;
  }

  .backdrop {
    z-index: 15000;
  }
}

.mobx > div > div > div {
  top: 1em !important;
  bottom: auto !important;
  left: 45%;
  right: auto !important;

  :focus {
    outline: none;
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.Toastify__toast--error {
  background-color: #000 !important;
}

.error-toastr {
  font-size: 11pt;
  background-color: #000000;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #ffffff;
}

.error-progress-bar-toaster {
  background-color: rgb(185, 185, 185) !important;
}

.padding-5 {
  padding: 5px;
}

.mutationError {
  width: 400px;
  right: 3em;
}

a {
  text-decoration: none;
  color: #ffffff;

  &:not(.bp3-menu-item):hover {
    &,
    > * {
      text-decoration: underline;
    }
  }
}

.mobx > div > div > div {
  top: 0.5em !important;
  bottom: auto !important;
  left: 45%;
  right: auto !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 20px;
}

.icon {
  cursor: pointer;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 900ms linear infinite forwards;
}

/* To counter blueprint js putting focus rectangles on things */
:focus {
  outline: none !important;
}

.standardScrollBar {
  &:hover {
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
}

@import 'blueprint.css';
