.paperMenu {
  width: 285px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  margin-top: 20px;
}

.list {
  padding: 0 !important;
}
