.tabsRoot {
  display: flex;
  justify-content: flex-start;
}
.tabsIndicator {
  background-color: 1px solid black;
}
.tabRoot {
  text-transform: initial !important;
  min-width: 72px;
  font-weight: 18px;
  margin-right: 32px;
  &:hover {
    color: var(--primary-dark);
    opacity: 1;
  }
  .tabSelected {
    color: var(--primary-dark);
    font-weight: 18px;
    border-bottom: 2px solid red;
  }
  &:focus {
    color: var(--darkgrey);
  }
}

.tabHeaders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px !important;
  margin: 0px 16px;
  border-bottom: 1px solid #e8e8e8;
}
.tabProductCount {
  display: flex;
  align-items: center;
}

.dragArea {
  padding: 4px 24px 4px !important;
}

.chip {
  margin: 8px;
  background: #ffffff !important;
  border: 1px solid lightgrey !important;
  cursor: pointer !important;
}

.label {
  padding-left: 4px;
}

.draggbleSource {
  background-color: transparent;
}
.draggbleSourceArea {
  display: flex;
  flex-wrap: wrap;
}

.dropTarget {
  display: flex;
  flex: 1;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
}

.allTagsContainer {
  min-height: calc(100vh / 3);
  margin-top: 16px;
  border-right: 1px solid black;
  flex: 1;
}
.containerTitle {
  display: flex;
  margin-left: 20px;
}
.excludeTagsContainer {
  flex: 1;
  margin-top: 16px;
}

.coreProductSelectionMsg {
  margin-top: 16px !important;
  color: #ffab07 !important;
  padding-left: 13px !important;
}
