.looks-wrapper {
  padding: 20px;
  /* margin-top: -25vh; Minus Height of Product carousel*/
}

.looks-wrapper .looks-container {
  display: flex;
  justify-content: flex-start;
  width: 600px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .looks-wrapper .looks-container {
    width: 455px;
  }
}

.looks-wrapper .looks-container .looks-media {
  height: 200px;
  width: 160px;
  margin-right: 30px;
}
