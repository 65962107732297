.flagIcon .flagIcon > img {
  height: 14.2px;
  width: 12.5px;
  padding-right: 5px;
}

.flagIcon > img {
  height: 14.2px;
  width: 12.5px;
}
