.m-l-10 {
  margin-left: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-auto {
  margin: auto;
}
