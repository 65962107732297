.recommended {
  display: flex;
}

.recommended-text {
  text-transform: uppercase;
}

.recommended > div > img {
  padding-left: 2px;
  height: 12px;
  width: 14px;
}
