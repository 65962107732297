.commentsCell {
  width: 200px;
}

.commentsCell div {
  width: 100%;
}

.inputClass {
  height: 25px;
}

.commentTextInput {
  font-size: 12px !important;
}

.helpText {
  color: rgba(0, 0, 0, 0.54) !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
