.root {
  flex: 1;
  justify-self: stretch;
  background-color: var(--background-paper);

  .icon {
    margin: var(--spacing-unit);
    color: var(--grey);
    cursor: pointer;

    //color: theme . colors . lightgrey
  }

  display: flex;
  flex-direction: column;

  > .buyingSessionBox {
    background: var(--background-grey-2);
    padding: 16px;
    flex: 0 0 auto;
    margin: 40px 44px 10px 44px;
  }
}

.sessionContainer {
  overflow: auto;
  padding: 2px; /* To make space for the box-shadows */

  .sessions {
    overflow: visible;
    display: grid;
    grid-gap: 1em;
    grid-auto-flow: dense;

    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

    .card {
      width: auto;
      min-width: 200px;

      user-select: none;
      border: thin solid transparent;
      transition: border-color var(--animation-medium);
      &:hover {
        &,
        * {
          text-decoration: none !important;
        }

        border-color: var(--card-hover-border-color);
      }
    }

    .addBuyingSession {
      grid-column-end: -1;
      justify-self: flex-end;
      align-self: flex-end;
      width: auto;
      height: auto;
      border-radius: 2px;
      background-color: var(--mid-grey);

      &,
      &:hover {
        color: var(--primary);
      }
    }
  }
}
