.product-media-carousel {
  max-height: fit-content;
}

.product-media-carousel .media-container {
  margin: -105px 0 0 140px;
  width: fit-content;
  height: fit-content;
  height: 95vh;
}

.product-media-carousel .media-container-large {
  margin: -80px 50px 0 130px;
}

.product-media-carousel .media-container-mid {
  height: 75vh;
}

.product-media-carousel .media-container-main {
  width: 470px;
  height: 455px;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .product-media-carousel .media-container-main {
    width: 325px;
  }
}

.product-media-carousel .media-container-main-large {
  width: 500px;
  height: 660px;
}

.product-media-carousel .media-container-close-IFrame {
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  position: relative;
}

.product-media-carousel .singleMedia-large {
  margin-top: 95px;
}

.product-media-carousel .singleMedia-mid {
  margin-top: 15px;
}
