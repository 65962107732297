.productDetails {
  display: flex;
  flex-direction: column;
}

.productDetailsHeader {
  display: flex;
  justify-content: center;
  width: 100%;

  ul {
    display: flex;

    li {
      display: inline;
      padding: 5px 10px;
      border-right: 1px solid var(--background-grey-5);
      opacity: 0.5;
    }

    li:last-child {
      border-right: unset !important;
    }
  }
}

.productDetailsSliderPlusInfoWrapper {
  display: flex;
  justify-content: center;
  margin: 10px 20px;
}

.productDetailsSliderWrapper {
  width: 55%;
  height: auto;
  min-height: 600px;
  margin: 10px;
}

.productDetailsSliderWrapper .status {
  position: sticky;
  z-index: 1;
}

.productDetailsSliderWrapper .status .statusTag {
  left: var(--thumnail-width);
}

.productDetailsInfoBox {
  display: flex;
  margin: 10px;
  flex-direction: column;
  width: 45%;
}

.productDetailsInfoBox .commentsContainer {
  margin-top: 20px;
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  max-height: 200px;
}

.list {
  list-style-type: none;
  display: flex;
  padding: 6px 16px;
  flex-grow: 1;
  flex-direction: column;
}

.listItem {
  display: flex;
  position: relative;
  list-style: none;
  min-height: 70px;
}

.listItem::before {
  /* flex: 1;
  content: "";
  padding: 6px 16px; */
}

.root {
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dot {
  display: flex;
  padding: 4px;
  align-self: baseline;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-top: 8px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  margin-bottom: 8px;
  color: var(--background-grey-2);
  border-color: transparent;
  background-color: var(--background-grey-6);
}

.line {
  width: 2px;
  flex-grow: 1;
  background-color: var(--background-grey-6);
}

.text {
  flex: 1;
  padding: 6px 16px;
}

.listItem:last-child > .root > .line {
  width: 0;
}

.recoTag {
  font: var(--typography-font-bold1);
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  margin: 4px;
  margin: 4px;
  top: 0;
}

.productRating {
  margin: 10px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .productInfoItemContent {
    display: flex;
    align-items: center;
    height: 50px;
    .productInfoItemText {
      font: var(--typography-headline-bold);
    }
  }
}
.cursor {
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
}
